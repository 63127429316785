import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import DeviceHealthConditional from '@totem/components/devices/health/deviceHealthConditional';
import DeviceHealthContainer from '@totem/components/devices/health/deviceHealthContainer';
import HealthGrid from '@totem/components/devices/health/HealthGrid';
import { hasHealth } from '@totem/components/devices/health/utilities';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import { DeviceQueryResult } from '@totem/types/devices';

const DeviceHeader = () => {
  const { deviceData } = useContext(DeviceContext);

  const formatDateTIme = (dateIn: string) => {
    if (dateIn !== null) {
      if (!isNaN(Date.parse(dateIn))) {
        const dateVal = new Date(dateIn);
        return <>{dateVal.toLocaleString()}</>;
      }
    }
    return <span>-</span>;
  };

  const getBuildingDisplay = (device: DeviceQueryResult) => {
    if (
      device.building !== null &&
      device.building.id !== null &&
      device.building.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/buildings/${device.building.id}`}>
          {device.building.name}
        </Link>
      );
    } else if (device.building !== null) {
      return <span>{device.building.name}</span>;
    }
    return <span>-</span>;
  };

  const getControlSystemDisplay = (device: DeviceQueryResult) => {
    if (
      device.controlSystem !== null &&
      device.controlSystem.id !== null &&
      device.controlSystem.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/controlsystems/${device.controlSystem.id}`}>
          {device.controlSystem.name}
        </Link>
      );
    } else if (device.controlSystem !== null) {
      return <span>{device.controlSystem.name}</span>;
    }
    return <span>-</span>;
  };

  return (
    <div>
      {typeof deviceData !== 'undefined' && deviceData !== null && (
        <>
          <Row>
            <Col span={8}>
              <HeaderLabel title="Device Name">
                {deviceData.device.displayName}
              </HeaderLabel>
              {deviceData.device.type !== null &&
                deviceData.device.type !== '' && (
                  <HeaderLabel title="Type">
                    {deviceData.device.type}
                  </HeaderLabel>
                )}
              {deviceData.device.vendor !== null &&
                deviceData.device.vendor !== '' && (
                  <HeaderLabel title="Vendor">
                    {deviceData.device.vendor}
                  </HeaderLabel>
                )}
            </Col>
            <Col span={8}>
              {deviceData.device.operatingSystem !== null &&
                deviceData.device.operatingSystem !== '' && (
                  <HeaderLabel title="Operating System">
                    {deviceData.device.operatingSystem}
                  </HeaderLabel>
                )}
              {deviceData.device.lastBackup !== null && (
                <HeaderLabel title="Last Backup">
                  {formatDateTIme(deviceData.device.lastBackup.backedUpAt)}
                  <br />
                  {deviceData.device.lastBackup.status}
                </HeaderLabel>
              )}
            </Col>
            <Col span={8}>
              <HeaderLabel title="Organization">
                {deviceData.organization.name}
              </HeaderLabel>
              {deviceData.region.name !== '' && (
                <HeaderLabel title="Region">
                  {deviceData.region.name}
                </HeaderLabel>
              )}
              {deviceData.building.name !== '' && (
                <HeaderLabel title="Building">
                  {getBuildingDisplay(deviceData)}
                </HeaderLabel>
              )}
              {deviceData.controlSystem.name !== '' && (
                <HeaderLabel title="Control System">
                  {getControlSystemDisplay(deviceData)}
                </HeaderLabel>
              )}
            </Col>
          </Row>
          {hasHealth(deviceData) && (
            <DeviceHealthContainer id={deviceData.device.id}>
              <DeviceHealthConditional>
                <br />
                <HealthGrid />
                <br />
                <br />
              </DeviceHealthConditional>
            </DeviceHealthContainer>
          )}
        </>
      )}
    </div>
  );
};

export default DeviceHeader;
