import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import {
  chartRequest,
  graphPoint,
} from '@totem/components/assetInventory/types';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { buildDeviceFilters } from '@totem/components/devices/devicesContainer/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { defaultChartColors } from '@totem/utilities/charts';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponse } from '@totem/utilities/responseUtilities';

type Props = {
  field: string;
  chartOptions?: ApexOptions;
  centerLabel?: string;
  onItemClicked?: (point: graphPoint) => void;
  onClick?: (field: string, label: string) => void;
};

const ChartHorizontalBarSummary = ({
  field,
  chartOptions,
  centerLabel,
  onItemClicked,
}: Props) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [chartValues, setChartValues] = useState<graphPoint[]>([]);
  const { input } = useContext(DeviceContext);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);

    const payload: chartRequest = {
      field,
      filters: buildDeviceFilters(input),
    };

    fetch(`${DEVICES_ENDPOINT}/chartTop`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    })
      .then((res) => CheckResponse(res))
      .then((res) => res.json())
      .then((result: graphPoint[]) => {
        setChartValues(result);
      })
      .finally(() => setIsFetching(false));
  }, [field, input]);

  const getSeriesData = () => {
    const data = chartValues.map((point) => point.y);
    return data;
  };

  const getLabels = () => {
    const data = chartValues.map((point) =>
      point.x !== '' ? point.x : 'Unknown',
    );
    return data;
  };

  // eslint-disable-next-line id-length
  const handleClick = (e: any, chart?: any, options?: any) => {
    const dataPointIndex = options.dataPointIndex;
    if (dataPointIndex >= 0) {
      const point = chartValues[dataPointIndex];
      onItemClicked(point);
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const options: ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: true, tools: { download: true } },
      events: {
        dataPointSelection: handleClick,
      },
    },
    title: {
      text: centerLabel,
      align: 'center',
      offsetY: -5,
    },
    labels: getLabels(),
    colors: defaultChartColors,
    legend: {
      width: 200,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {typeof chartValues !== 'undefined' &&
            chartValues !== null &&
            chartValues.length > 0 && (
              <>
                <ReactApexChart
                  options={getChartOptions()}
                  series={getSeriesData()}
                  labels={getLabels()}
                  type="donut"
                  width="100%"
                  height="400px"
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default ChartHorizontalBarSummary;
