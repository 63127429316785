import React, { useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import SquareFootageContext from '@totem/components/buildings/squareFootage/SquareFootageContext';
import { defaultChartColors } from '@totem/utilities/charts';
import { isNotNull } from '@totem/utilities/common';
import { sortNumberDescending } from '@totem/utilities/tableUtilities';

type Props = {
  chartOptions?: ApexOptions;
};

const TreeMapChart = ({ chartOptions }: Props) => {
  const { data } = useContext(SquareFootageContext);

  const getSeriesData = () => {
    if (isNotNull(data)) {
      return [
        {
          data: data
            .sort((compA, compB) =>
              sortNumberDescending(
                compA.totalSquareFootage,
                compB.totalSquareFootage,
              ),
            )
            .map((chk) => {
              return { x: chk.type, y: chk.totalSquareFootage };
            }),
        },
      ];
    }
    return [];
  };

  const options: ApexOptions = {
    chart: {
      type: 'treemap',
      toolbar: { show: true, tools: { download: true } },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
    colors: defaultChartColors,
    legend: {
      show: false,
    },
    title: {
      text: 'Square footage by building type',
      align: 'center',
    },
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {isNotNull(data) && data.length > 0 && (
            <ReactApexChart
              options={getChartOptions()}
              series={getSeriesData()}
              type="treemap"
              width="800px"
              height="400px"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TreeMapChart;
