import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ReactDOMServer from 'react-dom/server';
import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import { Badge, Col, Row } from 'antd';
import { ApexOptions } from 'apexcharts';

import {
  NewOldValueWithChange,
  PortfolioEndUsesResponseEndUse,
} from '@totem/components/energy/types';
import { defaultDonutChartTotalColor } from '@totem/utilities/charts';

const colorRiseBadge: string = '#FEE4E2';
const colorRiseText: string = '#B32419';
const colorFallBadge: string = '#D1FADF';
const colorFallText: string = '#2A744F';

type Props = {
  title: string;
  subTitle?: string;
  metric: string;
  unit?: string;
  useData?: PortfolioEndUsesResponseEndUse[];
  multiplier?: number;
  startDate: string;
  endDate: string;
};

const styles = {
  label: {
    fontWeight: 550,
  },
  value: {
    fontWeight: 400,
    align: 'right' as 'right',
    textAlign: 'right' as 'right',
  },
  trend: {
    fontWeight: 500,
    align: 'right' as 'right',
    textAlign: 'right' as 'right',
  },
  badge: {
    fontSize: '8px',
    marginRight: '5px',
  },
};

const chartColors = ['#FCEBAC', '#b3acfc', '#58BAA4', '#66A4CE', '#fcaccd'];

const EnergyEndUseChart = ({
  useData,
  multiplier,
  unit,
  title,
  subTitle,
}: Props) => {
  const valueMultiplier =
    typeof multiplier === 'number' && multiplier !== 0 ? multiplier : 1;
  const unitText = typeof unit === 'string' && unit !== null ? unit : '';

  const calculateTrend = (record: NewOldValueWithChange) => {
    if (record.change > 0) {
      return (
        <Badge
          color={colorRiseBadge}
          style={{ backgroundColor: colorRiseBadge, borderRadius: '20%' }}
          count={
            <span
              style={{ color: colorRiseText, padding: '2px', fontWeight: 700 }}
            >
              <RiseOutlined />
              &nbsp;
              {Math.round(record.change)}%
            </span>
          }
        />
      );
    } else if (record.change <= 0) {
      return (
        <Badge
          color={colorFallBadge}
          style={{ backgroundColor: colorFallBadge, borderRadius: '20%' }}
          count={
            <span
              style={{ color: colorFallText, padding: '2px', fontWeight: 700 }}
            >
              <FallOutlined />
              &nbsp;
              {Math.round(record.change * -1)}%
            </span>
          }
        />
      );
    }
    return <span>No Change</span>;
  };

  const getSeries = () => {
    return useData.map((rec) => rec.energy_consumption.now * valueMultiplier);
  };

  const getLabels = () => {
    return useData.map((rec) => rec.device);
  };

  const getLegendItems = () => {
    return useData.map((rec, idx) => {
      return ReactDOMServer.renderToStaticMarkup(
        <Row>
          <Col span={9} style={styles.label}>
            <Badge
              text={rec.device}
              color={chartColors[idx % chartColors.length]}
              style={styles.badge}
            />
          </Col>
          <Col span={9} style={styles.value}>
            {(rec.energy_consumption.now * valueMultiplier).toFixed(2)}{' '}
            {unitText}
          </Col>
          <Col span={6} style={styles.trend}>
            {calculateTrend({
              old: rec.energy_consumption.old,
              now: rec.energy_consumption.now,
              change:
                rec.energy_consumption.old === 0
                  ? rec.energy_consumption.now === 0
                    ? 0
                    : 100
                  : Math.round(
                      ((rec.energy_consumption.now -
                        rec.energy_consumption.old) /
                        rec.energy_consumption.old) *
                        100,
                    ),
            })}
          </Col>
        </Row>,
      );
    });
  };

  const getTotal = () => {
    let total = 0;

    for (let idx = 0; idx < useData.length; idx++) {
      total += useData[idx].energy_consumption.now;
    }

    return total * valueMultiplier;
  };

  const options: ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: true, tools: { download: true } },
      width: 200,
      height: 200,
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#1d2939',
      },
      margin: 0,
      offsetY: 0,
    },
    subtitle: {
      text: subTitle,
      align: 'left',
      style: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#626b7f',
      },
      margin: 0,
      offsetY: 20,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.6,
        donut: {
          size: '85%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '40px',
              fontWeight: 300,
              color: defaultDonutChartTotalColor,
              label: `${getTotal().toFixed(0)} ${unitText}`,
              formatter(): string {
                return '';
              },
            },
          },
        },
      },
    },
    labels: getLabels(),
    colors: chartColors,
    legend: {
      show: true,
      position: 'right',
      horizontalAlign: 'left',
      width: 300,
      customLegendItems: getLegendItems(),
      offsetX: -30,
      offsetY: 60,
      itemMargin: {
        vertical: 0,
      },
      markers: {
        width: 0,
        height: 0,
        strokeColor: '#ffffff',
        fillColors: ['#ffffff'],
        radius: 0,
        customHTML(): any {
          return '';
        },
      },
    },
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart
            options={options}
            series={getSeries()}
            type="donut"
            width="100%"
            height="400px"
          />
        </div>
      </div>
    </div>
  );
};

export default EnergyEndUseChart;
