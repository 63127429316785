import React from 'react';

import '../ticketing.css';

const HeaderLabel = (props: any) => {
  const { containerStyles: customStyles } = props;
  const containerStyles = { ...customStyles };

  return (
    <div style={containerStyles}>
      <span styleName="header-label">{props.title}</span>
      <br />
      {props.children}
    </div>
  );
};

export default HeaderLabel;
