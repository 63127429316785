import React from 'react';
import { Col, Flex, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import CountBadge from '@totem/components/software/software_report/countBadge';
import MergeButton from '@totem/components/software/software_report/mergeButton';
import SoftwareContainer from '@totem/components/software/software_report/softwareContainer';
import SoftwareFilters from '@totem/components/software/software_report/softwareFilters';
import SoftwareTable from '@totem/components/software/software_report/softwareTable';

const styles = {
  inset: {
    marginLeft: '20px',
    marginRight: '20px',
  },
};

const SoftwarePage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <SoftwareContainer>
            <br />
            <SoftwareFilters />
            <br />
            <br />
            <div style={styles.inset}>
              <Row>
                <Col span={12}>
                  <Flex justify={'flex-start'} align={'center'}>
                    <MergeButton isPrimary />
                  </Flex>
                </Col>
                <Col span={12}>
                  <Flex justify={'flex-end'} align={'center'}>
                    <CountBadge />
                  </Flex>
                </Col>
              </Row>
              <br />
              <SoftwareTable />
            </div>
          </SoftwareContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default SoftwarePage;
