export const LOCAL = 'localhost';
export const DEV = 'dev';
export const STAGING = 'staging';
export const PRODUCTION = 'prod';
export const DEV_NEW = 'dev_new';
export const STAGING_NEW = 'staging_new';
export const PRODUCTION_NEW = 'prod_new';

let environment;
if (window.location.hostname.includes('localhost')) {
  environment = LOCAL;
} else if (window.location.hostname.includes('d-dashboard')) {
  environment = DEV_NEW;
} else if (window.location.hostname.includes('d-ras')) {
  environment = DEV_NEW;
} else if (window.location.hostname.includes('s-dashboard')) {
  environment = STAGING_NEW;
} else if (window.location.hostname.includes('dashboard')) {
  environment = PRODUCTION_NEW;
} else if (window.location.hostname.includes('ras')) {
  environment = PRODUCTION_NEW;
} else if (window.location.hostname.includes('dev')) {
  environment = DEV;
} else if (window.location.hostname.includes('staging')) {
  environment = STAGING;
} else {
  environment = PRODUCTION;
}

const isLocal = environment === LOCAL;
const isDev = environment === DEV;
const isStaging = environment === STAGING;
const isProd = environment === PRODUCTION;
const isDevNew = environment === DEV_NEW;
const isStagingNew = environment === STAGING_NEW;
const isProdNew = environment === PRODUCTION_NEW;

const configuration = {
  constants: {
    LOCAL,
    DEV,
    PRODUCTION,
    DEV_NEW,
    STAGING_NEW,
    PRODUCTION_NEW,
  },
  environment,
  isLocal,
  isDev,
  isDevNew,
  isStagingNew,
  isProdNew,
  isStaging,
  isProd,
  AUTH_DOMAIN: isLocal
    ? 'd-intelligentbuildings.us.auth0.com'
    : isDevNew
      ? 'd-intelligentbuildings.us.auth0.com'
      : 'auth.ib-services.net',
  AUTH_AUDIENCE: isLocal
    ? 'https://d-intelligentbuildings.us.auth0.com/userinfo'
    : isDevNew
      ? 'https://d-intelligentbuildings.us.auth0.com/userinfo'
      : 'https://intelligentbuildings.auth0.com/userinfo',
  AUTH_CLIENT_ID: isProd
    ? 'A67rxWWE6p2ei0svwfM5u0pRSkCulCFo'
    : isProdNew
      ? 'BbBuw4osp1ADuMapMimIb4TAPDS3ZH66'
      : isStaging
        ? 'ZYN6aSlKCbC4909hVgo2uHC8HJCMt9Vs'
        : isStagingNew
          ? '8BUH9OWtkS1owPxnKcbq4gXvYxh4FVWa'
          : isDevNew
            ? 'aUKKXzo0ZnyilQ2kCNwKoNQvKDCjMkN3'
            : isDev
              ? 'Bsy4FBErxbST5HjM0MuclcZTMMVBbsZA'
              : isLocal
                ? 'Unx8xZLRar3cTWrfkyh0UKekjB9pl3YS'
                : 'b67v9OND9mq1dBunXcZikw7tyoM2G2h7',
  AUTH_RESPONSE_TYPE: 'code',
  AUTH_SCOPE: 'openid profile email',
  AUTH_CALLBACK_URL: isProd
    ? 'https://api.totembuildings.com/api/v1/auth/callback'
    : isProdNew
      ? 'https://dashboard-api.ib-services.net/api/v1/auth/callback'
      : isStaging
        ? 'https://staging-api.totembuildings.com/api/v1/auth/callback'
        : isStagingNew
          ? 'https://s-dashboard-api.ib-services.net/api/v1/auth/callback'
          : isDev
            ? 'https://dev-api.totembuildings.com/api/v1/auth/callback'
            : isDevNew
              ? 'https://d-dashboard-api.ib-services.net/api/v1/auth/callback'
              : isLocal
                ? 'http://localhost:8090/api/v1/auth/callback'
                : 'http://localhost:8090/api/v1/auth/callback',
  API_DOMAIN: isProd
    ? 'https://api.totembuildings.com'
    : isProdNew
      ? 'https://dashboard-api.ib-services.net'
      : isStaging
        ? 'https://staging-api.totembuildings.com'
        : isStagingNew
          ? 'https://s-dashboard-api.ib-services.net'
          : isDev
            ? 'https://dev-api.totembuildings.com'
            : isDevNew
              ? 'https://d-dashboard-api.ib-services.net'
              : isLocal
                ? 'http://localhost:8090'
                : 'http://localhost:8090',
  flags: {
    vendorOrgsEnabled: isLocal || isDev || isDevNew,
    useFullStory: false, //isDev || isDevNew || isProd || isProdNew,
  },
};

export default configuration;
