import React from 'react';

import DeviceContainer from '@totem/components/devices/deviceDetails/DeviceContainer';
import DeviceHeader from '@totem/components/devices/deviceDetails/DeviceHeader';
import DeviceTabs from '@totem/components/devices/deviceDetails/DeviceTabs';
import EventEmbeddedFilterContainer from '@totem/components/events/eventFilters/EventEmbeddedFilterContainer';
import EventEmbeddedContainer from '@totem/components/events/eventsContainer/EventEmbeddedContainer';
import VulnerabilityReportContainer from '@totem/components/software/vulnerabilityReport/vulnerabilityReportContainer';

type Props = {
  deviceId: string;
};

const DeviceDetailsLayout = ({ deviceId }: Props) => {
  const getDefaultEndDate = () => {
    const endDate = new Date();
    return endDate;
  };

  const getDefaultStartDate = () => {
    const startDate = getDefaultEndDate();
    startDate.setDate(startDate.getDate() - 120);
    return startDate;
  };

  return (
    <>
      <DeviceContainer deviceId={deviceId}>
        <EventEmbeddedFilterContainer
          startTime={getDefaultStartDate().valueOf()}
          endTime={getDefaultEndDate().valueOf()}
          staticFilters={{
            deviceId: [deviceId],
          }}
        >
          <EventEmbeddedContainer>
            <DeviceHeader />
            <VulnerabilityReportContainer deviceId={[deviceId]}>
              <DeviceTabs />
            </VulnerabilityReportContainer>
          </EventEmbeddedContainer>
        </EventEmbeddedFilterContainer>
      </DeviceContainer>
    </>
  );
};

export default DeviceDetailsLayout;
