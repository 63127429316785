import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import AddEditDialog from '@totem/components/buildingsCommon/AddEditDialog';
import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import { BuildingReportRecord } from '@totem/components/buildingSummary/types';
import Table from '@totem/components/common/table/Table';
import AuditGrade from '@totem/components/controlSystemReport/AuditGrade';
import TableTitle from '@totem/components/controlSystemReport/TableTitle';
import colors from '@totem/styles/colors';
import { getToken } from '@totem/utilities/accountUtilities';
import authUtilities from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';
import { V2_BUILDINGS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

import './buildingSummary.css';

const styles = {
  button: {
    marginRight: '4px',
  },
};

const BuildingSummaryTable = () => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const { reportData, input, setInput, loading, totalRecords, onAction } =
    useContext(BuildingSummaryContext);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [selectedForEdit, setSelectedForEdit] =
    useState<BuildingReportRecord>(null);
  const [action, setAction] = useState<string>('');
  const isDisabled = !authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const sendDeleteBuilding = useCallback(
    async (record: BuildingReportRecord) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      const endPoint = `${V2_BUILDINGS_ENDPOINT}/${record.id}`;

      fetch(endPoint, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then((res) => {
        CheckResponseShowError(res);
        setIsSending(false);
        onAction('refresh');
      });
    },
    [],
  );

  const handleEditClicked = (record: BuildingReportRecord) => {
    setShowEditDialog(true);
    setSelectedForEdit(record);
    setAction('edit');
  };

  const handleDeleteClicked = (record: BuildingReportRecord) => {
    Modal.confirm({
      content: `Are you sure you want to remove ${record.name.trim()}?`,
      title: `Remove ${record.name}?`,
      cancelText: 'No',
      okText: 'Yes, Delete',
      okType: 'danger',
      icon: <CloseCircleFilled />,
      onOk: () => sendDeleteBuilding(record),
    });
  };

  const handleCloseDialog = (refresh: boolean) => {
    setShowEditDialog(false);
    setAction('');
    setSelectedForEdit(null);
    if (refresh) {
      onAction('refresh');
    }
  };

  const columns: ColumnProps<BuildingReportRecord>[] = [
    {
      title: <TableTitle>Building Name</TableTitle>,
      dataIndex: 'building.name',
      key: 'buildingId',
      render: (_, record: BuildingReportRecord) => (
        <Link to={`/dashboard/buildings/${record.id}`}>
          {record.name}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: <TableTitle>Address</TableTitle>,
      dataIndex: 'addressOne',
      key: 'addressOne',
      render: (_, record: BuildingReportRecord) => (
        <span>{record.addressOne}</span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.addressOne === compB.addressOne
          ? 0
          : compA.addressOne > compB.addressOne
            ? 1
            : -1,
    },
    {
      title: <TableTitle>City</TableTitle>,
      dataIndex: 'city',
      key: 'city',
      render: (_, record: BuildingReportRecord) => <span>{record.city}</span>,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.city === compB.city ? 0 : compA.city > compB.city ? 1 : -1,
    },
    {
      title: <TableTitle>State</TableTitle>,
      dataIndex: 'state',
      key: 'state',
      render: (_, record: BuildingReportRecord) => <span>{record.state}</span>,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.state === compB.state ? 0 : compA.state > compB.state ? 1 : -1,
    },
    {
      title: <TableTitle>Region</TableTitle>,
      dataIndex: 'region.name',
      key: 'region.name',
      render: (_, record: BuildingReportRecord) => (
        <span>{record.region.name}</span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.region.name === compB.region.name
          ? 0
          : compA.region.name > compB.region.name
            ? 1
            : -1,
    },
    {
      title: <TableTitle>Control Systems</TableTitle>,
      dataIndex: 'controlSystems',
      key: 'controlSystems',
      render: (_, record: BuildingReportRecord) => (
        <span>
          {isNotNull(record.controlSystems) ? record.controlSystems.length : 0}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.controlSystems.length === compB.controlSystems.length
          ? 0
          : compA.controlSystems.length > compB.controlSystems.length
            ? 1
            : -1,
    },
    {
      title: <TableTitle>Audit Grade</TableTitle>,
      dataIndex: 'grade',
      key: 'grade',
      render: (_, record: BuildingReportRecord) => (
        <div styleName="gradeLink">
          {isNotNull(record.policyAudit) &&
            isNotNull(record.policyAudit.totalAudits) &&
            isNotNull(record.policyAudit.maxScore) &&
            isNotNull(record.policyAudit.totalScore) &&
            record.policyAudit.totalAudits > 0 && (
              <AuditGrade
                score={
                  (record.policyAudit.totalScore /
                    record.policyAudit.maxScore) *
                  100
                }
                size="small"
                style={{ margin: '0 auto' }}
              />
            )}
        </div>
      ),
    },
    {
      title: <TableTitle>Actions</TableTitle>,
      dataIndex: '',
      width: '84px',
      render: (_, building) => (
        <div className="center-table-cell">
          <Tooltip title="Edit" placement="top">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              disabled={isDisabled}
              style={styles.button}
              onClick={() => handleEditClicked(building)}
            />
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <Button
              shape="circle"
              icon={<DeleteOutlined color={colors.neutral.gray} />}
              disabled={isDisabled}
              onClick={() => handleDeleteClicked(building)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const getData = () => {
    if (isNotNull(reportData) && isNotNull(reportData.report)) {
      return reportData.report;
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<BuildingReportRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <>
      <Table
        showSorterTooltip
        columns={columns}
        dataSource={getData()}
        onChange={handleTableChange}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={{
          current: input.page,
          pageSize: input.pageSize,
          total: totalRecords,
          showSizeChanger: true,
        }}
      />
      {showEditDialog &&
        isNotNull(selectedForEdit) &&
        action === 'edit' &&
        !isDisabled && (
          <AddEditDialog
            open={showEditDialog}
            loading={loading}
            buildingData={{
              id: selectedForEdit.id,
              name: selectedForEdit.name,
              type: selectedForEdit.type,
              squareFootage: selectedForEdit.squareFootage,
              address: {
                addressOne: selectedForEdit.addressOne,
                addressTwo: selectedForEdit.addressTwo,
                city: selectedForEdit.city,
                state: selectedForEdit.state,
                zipCode: selectedForEdit.postalCode,
                country: selectedForEdit.country,
                location: selectedForEdit.location,
              },
              region: selectedForEdit.region,
            }}
            onClose={handleCloseDialog}
          />
        )}
    </>
  );
};

export default BuildingSummaryTable;
