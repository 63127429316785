import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import DeviceHealthContext from '@totem/components/devices/health/deviceHealthContext';
import EventContext from '@totem/components/events/eventsContainer/eventContainerContext';
import colors from '@totem/styles/colors';
import { isNull } from '@totem/utilities/common';

const COLOR_RED: string = colors.event.criticality.background.critical;
const COLOR_ORANGE: string = colors.event.criticality.background.major;
const COLOR_YELLOW: string = colors.event.criticality.background.warning;
const COLOR_GREEN: string = colors.event.criticality.background.ok;
const COLOR_TEXT: string = colors.event.criticality.foreground.critical;
// const COLOR_RED: string = '#FFADAD';
// const COLOR_ORANGE: string = '#FFD6A5';
// const COLOR_YELLOW: string = '#FDFFB6';
// const COLOR_GREEN: string = '#CAFFBF';
//const COLOR_BLUE: string = '#9BF6FF';

const styles = {
  title: {
    fontSize: '10pt',
    height: 30,
    maxHeight: 30,
    minHeight: 30,
  },
  value: {
    fontWeight: 700,
    fontSize: '20pt',
    height: 50,
    maxHeight: 50,
    minHeight: 50,
  },
  boarderLeft: {
    borderLeft: '2px solid #e1e1e1',
  },
  containerSide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBottom: {
    //borderLeft: '2px solid #e1e1e1',
    boxShadow: '3px 3px 3px 3px rgba(150,150,150,0.75)',
    borderRadius: '10px 10px 10px 10px',
    fontWeight: 700,
    fontSize: '14pt',
  },
};

const HealthGrid = () => {
  const { data } = useContext(DeviceHealthContext);
  const { eventData } = useContext(EventContext);

  const criticalEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
        (evt) => evt.severity === 5 && evt.status !== 'CLEARED',
      );
  const majorEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
        (evt) => evt.severity === 4 && evt.status !== 'CLEARED',
      );
  const minorEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
        (evt) => evt.severity === 3 && evt.status !== 'CLEARED',
      );

  const colorStatus = {
    backgroundColor: data.offline ? COLOR_RED : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorInstallIssues = {
    backgroundColor: data.installationIssuesCount > 0 ? COLOR_RED : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorPendingReboot = {
    backgroundColor: data.pendingRebootReason === '' ? COLOR_GREEN : COLOR_RED,
    color: COLOR_TEXT,
  };
  const colorOSPatches = {
    backgroundColor:
      data.failedOSPatchesCount > 0
        ? COLOR_RED
        : data.pendingOSPatchesCount > 0
          ? COLOR_YELLOW
          : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorOSPatchesPending = {
    backgroundColor:
      data.pendingOSPatchesCount > 0 ? COLOR_YELLOW : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorOSPatchesFailed = {
    backgroundColor: data.failedOSPatchesCount > 0 ? COLOR_RED : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorSWPatches = {
    backgroundColor:
      data.failedSoftwarePatchesCount > 0
        ? COLOR_RED
        : data.pendingSoftwarePatchesCount > 0
          ? COLOR_YELLOW
          : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorSWPatchesPending = {
    backgroundColor:
      data.pendingSoftwarePatchesCount > 0 ? COLOR_YELLOW : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorSWPatchesFailed = {
    backgroundColor:
      data.failedSoftwarePatchesCount > 0 ? COLOR_RED : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorThreats = {
    backgroundColor: COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorEvents = {
    backgroundColor:
      criticalEvents.length > 0
        ? COLOR_RED
        : majorEvents.length > 0
          ? COLOR_ORANGE
          : minorEvents.length > 0
            ? COLOR_YELLOW
            : COLOR_GREEN,
    color: COLOR_TEXT,
  };
  const colorCriticalEvents = {
    backgroundColor: criticalEvents.length === 0 ? COLOR_GREEN : COLOR_RED,
    color: COLOR_TEXT,
  };
  const colorMajorEvents = {
    backgroundColor: majorEvents.length === 0 ? COLOR_GREEN : COLOR_ORANGE,
    color: COLOR_TEXT,
  };
  const colorMinorEvents = {
    backgroundColor:
      minorEvents.length === 0
        ? COLOR_GREEN
        : colors.event.criticality.background.minor,
    color: COLOR_TEXT,
  };

  return (
    <div style={styles.containerBottom}>
      <Row gutter={[0, 0]} justify={'center'}>
        <Col
          span={2}
          style={{ ...styles.containerSide, ...styles.title, ...colorStatus }}
        />
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorInstallIssues,
          }}
        />
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorPendingReboot,
          }}
        />
        <Col
          span={4}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorOSPatches,
          }}
        >
          OS Patches
        </Col>
        <Col
          span={4}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorSWPatches,
          }}
        >
          Software Patches
        </Col>
        <Col
          span={4}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorThreats,
          }}
        >
          Threats
        </Col>
        <Col
          span={6}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorEvents,
          }}
        >
          Alerts
        </Col>
      </Row>
      <Row gutter={[0, 0]} justify={'center'}>
        <Col
          span={2}
          style={{ ...styles.containerSide, ...styles.title, ...colorStatus }}
        >
          Status
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorInstallIssues,
          }}
        >
          Install Issues
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorPendingReboot,
          }}
        >
          Pending Reboot
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorOSPatchesPending,
          }}
        >
          Pending
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...colorOSPatchesFailed,
          }}
        >
          Failed
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorSWPatchesPending,
          }}
        >
          Pending
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...colorSWPatchesFailed,
          }}
        >
          Failed
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorThreats,
          }}
        >
          Blocked
        </Col>
        <Col
          span={2}
          style={{ ...styles.containerSide, ...styles.title, ...colorThreats }}
        >
          Quarantined
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...styles.boarderLeft,
            ...colorCriticalEvents,
          }}
        >
          Critical
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...colorMajorEvents,
          }}
        >
          Major
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.title,
            ...colorMinorEvents,
          }}
        >
          Minor
        </Col>
      </Row>
      <Row gutter={[0, 0]} justify={'center'}>
        <Col
          span={2}
          style={{ ...styles.containerSide, ...styles.value, ...colorStatus }}
        >
          {data.offline ? 'Offline' : 'Online'}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...styles.boarderLeft,
            ...colorInstallIssues,
          }}
        >
          {String(data.installationIssuesCount)}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...styles.boarderLeft,
            ...colorPendingReboot,
          }}
        >
          <span title={data.pendingRebootReason}>
            {data.pendingRebootReason !== '' ? 'Yes' : 'No'}
          </span>
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...styles.boarderLeft,
            ...colorOSPatchesPending,
          }}
        >
          {data.pendingOSPatchesCount}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...colorOSPatchesFailed,
          }}
        >
          {data.failedOSPatchesCount}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...styles.boarderLeft,
            ...colorSWPatchesPending,
          }}
        >
          {data.pendingSoftwarePatchesCount}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...colorSWPatchesFailed,
          }}
        >
          {data.failedSoftwarePatchesCount}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...styles.boarderLeft,
            ...colorThreats,
          }}
        >
          {data.blockedThreatsCount}
        </Col>
        <Col
          span={2}
          style={{ ...styles.containerSide, ...styles.value, ...colorThreats }}
        >
          {data.quarantinedThreatsCount}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...styles.boarderLeft,
            ...colorCriticalEvents,
          }}
        >
          {criticalEvents.length}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...colorMajorEvents,
          }}
        >
          {majorEvents.length}
        </Col>
        <Col
          span={2}
          style={{
            ...styles.containerSide,
            ...styles.value,
            ...colorMinorEvents,
          }}
        >
          {minorEvents.length}
        </Col>
      </Row>
    </div>
  );
};

export default HealthGrid;
