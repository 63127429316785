import React, { useContext } from 'react';
import { MergeCellsOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import SoftwareContext from '@totem/components/software/software_report/softwareContext';

export interface Props {
  isPrimary?: boolean;
}
const MergeButton = ({ isPrimary }: Props) => {
  const { selectedPackages, action, onAction } = useContext(SoftwareContext);

  return (
    <Button
      icon={<MergeCellsOutlined />}
      type={
        typeof isPrimary !== 'undefined' && isPrimary ? 'primary' : 'default'
      }
      disabled={
        action === 'merge' ||
        typeof selectedPackages === 'undefined' ||
        selectedPackages === null ||
        selectedPackages.length < 2
      }
      onClick={() => onAction('merge', null)}
    >
      Merge
    </Button>
  );
};

export default MergeButton;
