import React, { useContext } from 'react';
import { Tabs, TabsProps } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import AttributesTable from '@totem/components/devices/deviceDetails/AttributesTable';
import ContractLinesTable from '@totem/components/devices/deviceDetails/ContractLinesTable';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import DeviceKeysTable from '@totem/components/devices/deviceDetails/deviceKeys/DeviceKeysTable';
import DeviceVariablesTable from '@totem/components/devices/deviceDetails/deviceVariables/DeviceVariablesTable';
import { hasVariables } from '@totem/components/devices/deviceDetails/deviceVariables/utilities';
import DriveCards from '@totem/components/devices/deviceDetails/DriveCards';
import EventsTable from '@totem/components/devices/deviceDetails/EventsTable';
import MemorySlotsTable from '@totem/components/devices/deviceDetails/MemorySlotTable';
import NetworkDetails from '@totem/components/devices/deviceDetails/NetworkDetails';
import PeripheralsTable from '@totem/components/devices/deviceDetails/PeripheralsTable';
import VulnerabilityReportTable from '@totem/components/devices/deviceDetails/softwareVulnerabilityTable';
import VideoCardsTable from '@totem/components/devices/deviceDetails/VideoCardTable';
import NetworkContainer from '@totem/components/devices/network/networkContainer';
import NetworkTable from '@totem/components/devices/network/networkTable';
import { hasNetworkInterfaces } from '@totem/components/devices/network/utilities';
import OSPatchInstallsContainer from '@totem/components/devices/os-patch-installs/osPatchInstallContainer';
import OSPatchInstallsTable from '@totem/components/devices/os-patch-installs/osPatchInstallTable';
import { hasOSPatchInstalls } from '@totem/components/devices/os-patch-installs/utilities';
import OSPatchContainer from '@totem/components/devices/os-patches/osPatchContainer';
import OSPatchTable from '@totem/components/devices/os-patches/osPatchTable';
import { hasOSPatches } from '@totem/components/devices/os-patches/utilities';
import ProcessorsContainer from '@totem/components/devices/processors/processorsContainer';
import ProcessorsTable from '@totem/components/devices/processors/processorsTable';
import { hasProcessors } from '@totem/components/devices/processors/utilities';
import ServiceContainer from '@totem/components/devices/services/serviceContainer';
import ServicesTable from '@totem/components/devices/services/servicesTable';
import { hasServices } from '@totem/components/devices/services/utilities';
import SoftwarePatchInstallsContainer from '@totem/components/devices/softrware-patch-installs/softwarePatchInstallContainer';
import SoftwarePatchInstallsTable from '@totem/components/devices/softrware-patch-installs/softwarePatchInstallsTable';
import { hasSoftwarePatchInstalls } from '@totem/components/devices/softrware-patch-installs/utilities';
import SoftwareContainer from '@totem/components/devices/software/softwareContainer';
import SoftwareTable from '@totem/components/devices/software/softwareTable';
import { hasSoftware } from '@totem/components/devices/software/utilities';
import SoftwarePatchContainer from '@totem/components/devices/software-patches/softwarePatchesContainer';
import SoftwarePatchesTable from '@totem/components/devices/software-patches/softwarePatchesTable';
import { hasSoftwarePatches } from '@totem/components/devices/software-patches/utilities';
import { hasVolumes } from '@totem/components/devices/volumes/utilities';
import VolumeCards from '@totem/components/devices/volumes/volumeCards';
import VolumesContainer from '@totem/components/devices/volumes/volumeContainer';
import VolumeTable from '@totem/components/devices/volumes/volumeTable';
import TicketList from '@totem/components/events/detailView/TicketList';
import VulnerabilityReportContext from '@totem/components/software/vulnerabilityReport/vulnerabilityReportContext';
import TabTitle from '@totem/components/TabTitle';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import TicketEmbeddedContainer from '@totem/components/ticketing/listContainer/TicketEmbeddedContainer';
import UserProfileContext from '@totem/components/UserProfileContext';
import authUtilities from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';
import { isIBUser } from '@totem/utilities/security';
import {
  getUserRole,
  hasActiveService,
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

const DeviceTabs = () => {
  const { deviceData } = useContext(DeviceContext);
  const { userProfile } = useContext(UserProfileContext);
  const { data } = useContext(VulnerabilityReportContext);
  const canSeeKeys =
    userProfile.organization.role === 3 && isIBUser(userProfile);
  const hasAdvancedManagement =
    hasActiveService(userProfile, 'advanced_management') ||
    getUserRole(userProfile) === 3;

  const canSeeContracts =
    isMemberOfAny(userProfile, ['contract_viewer', 'contract_creator'], {
      regionId:
        typeof deviceData !== 'undefined' && deviceData !== null
          ? deviceData.device.regionId
          : EMPTY_ID,
      buildingId:
        typeof deviceData !== 'undefined' && deviceData !== null
          ? deviceData.device.buildingId
          : EMPTY_ID,
      controlSystemId:
        typeof deviceData !== 'undefined' && deviceData !== null
          ? deviceData.device.controlSystemId
          : EMPTY_ID,
    }) || authUtilities.isSystemsAdmin();

  const userCanSeeTicketing =
    isMemberOfAny(
      userProfile,
      [
        'ticket_viewer',
        'ticket_updater',
        'ticket_creator',
        'ticket_technician',
        'ticket_administrator',
      ],
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) === 3;

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [];

    if (
      deviceData.device.attributes !== null &&
      deviceData.device.attributes.length > 0
    ) {
      tabItems.push({
        key: 'attributes',
        label: <TabTitle>Attributes</TabTitle>,
        children: <AttributesTable />,
      });
    }

    if (hasSoftware(deviceData)) {
      tabItems.push({
        key: 'software',
        label: <TabTitle>Software</TabTitle>,
        children: (
          <SoftwareContainer id={deviceData.device.id}>
            <SoftwareTable />,
          </SoftwareContainer>
        ),
      });

      if (isNotNull(data) && isNotNull(data.data) && data.data.length > 0) {
        tabItems.push({
          key: 'software_vulnerabilities',
          label: <TabTitle>Vulnerabilities</TabTitle>,
          children: (
            <VulnerabilityReportTable
              deviceId={deviceData.device.id}
              deviceName={deviceData.device.displayName}
            />
          ),
        });
      }
    }

    if (hasServices(deviceData)) {
      tabItems.push({
        key: 'services',
        label: <TabTitle>Services</TabTitle>,
        children: (
          <ServiceContainer id={deviceData.device.id}>
            <ServicesTable />
          </ServiceContainer>
        ),
      });
    }

    if (hasProcessors(deviceData)) {
      tabItems.push({
        key: 'processors',
        label: <TabTitle>Processors</TabTitle>,
        children: (
          <ProcessorsContainer id={deviceData.device.id}>
            <ProcessorsTable />
          </ProcessorsContainer>
        ),
      });
    }

    if (hasVolumes(deviceData)) {
      tabItems.push({
        key: 'drives',
        label: <TabTitle>Volumes</TabTitle>,
        children: (
          <VolumesContainer id={deviceData.device.id}>
            <VolumeCards />
            <br />
            <VolumeTable />
          </VolumesContainer>
        ),
      });
    }

    if (
      !hasVolumes(deviceData) &&
      hasAdvancedManagement &&
      deviceData.device.drives !== null &&
      deviceData.device.drives.length > 0
    ) {
      tabItems.push({
        key: 'drives',
        label: <TabTitle>Volumes</TabTitle>,
        children: <DriveCards />,
      });
    }

    if (
      hasAdvancedManagement &&
      deviceData.device.peripherals !== null &&
      deviceData.device.peripherals.length > 0
    ) {
      tabItems.push({
        key: 'peripherals',
        label: <TabTitle>Peripherals</TabTitle>,
        children: <PeripheralsTable />,
      });
    }

    if (
      hasAdvancedManagement &&
      deviceData.device.memorySlots !== null &&
      deviceData.device.memorySlots.length > 0
    ) {
      tabItems.push({
        key: 'memory',
        label: <TabTitle>Memory</TabTitle>,
        children: <MemorySlotsTable />,
      });
    }

    if (
      hasAdvancedManagement &&
      deviceData.device.videoCards !== null &&
      deviceData.device.videoCards.length > 0
    ) {
      tabItems.push({
        key: 'video',
        label: <TabTitle>Video</TabTitle>,
        children: <VideoCardsTable />,
      });
    }

    tabItems.push({
      key: 'network',
      label: <TabTitle>Network</TabTitle>,
      children: (
        <>
          <NetworkDetails />
          {hasNetworkInterfaces(deviceData) && (
            <NetworkContainer id={deviceData.device.id}>
              <br />
              <br />
              <NetworkTable />
            </NetworkContainer>
          )}
        </>
      ),
    });

    if (hasOSPatchInstalls(deviceData)) {
      tabItems.push({
        key: 'os-patch-installs',
        label: <TabTitle>OS Patch Installs</TabTitle>,
        children: (
          <OSPatchInstallsContainer id={deviceData.device.id}>
            <OSPatchInstallsTable />
          </OSPatchInstallsContainer>
        ),
      });
    }

    if (hasSoftwarePatchInstalls(deviceData)) {
      tabItems.push({
        key: 'software-patch-installs',
        label: <TabTitle>Software Patch Installs</TabTitle>,
        children: (
          <SoftwarePatchInstallsContainer id={deviceData.device.id}>
            <SoftwarePatchInstallsTable />
          </SoftwarePatchInstallsContainer>
        ),
      });
    }

    if (hasOSPatches(deviceData) || hasSoftwarePatches(deviceData)) {
      tabItems.push({
        key: 'os-patches',
        label: <TabTitle>Patches</TabTitle>,
        children: (
          <>
            {hasOSPatches(deviceData) && (
              <>
                <HeaderLabel title="OS Patches" />
                <OSPatchContainer id={deviceData.device.id}>
                  <OSPatchTable />
                </OSPatchContainer>
                <br />
                <br />
              </>
            )}
            {hasSoftwarePatches(deviceData) && (
              <>
                <HeaderLabel title="Software Patches" />
                <SoftwarePatchContainer id={deviceData.device.id}>
                  <SoftwarePatchesTable />
                </SoftwarePatchContainer>
              </>
            )}
          </>
        ),
      });
    }

    if (
      canSeeContracts &&
      deviceData.device.contractLines !== null &&
      deviceData.device.contractLines.length > 0
    ) {
      tabItems.push({
        key: 'contract',
        label: <TabTitle>Contract Lines</TabTitle>,
        children: <ContractLinesTable />,
      });
    }

    tabItems.push({
      key: 'events',
      label: <TabTitle>Events</TabTitle>,
      children: <EventsTable />,
    });

    if (userCanSeeTicketing) {
      tabItems.push({
        key: 'tickets',
        label: <TabTitle>Tickets</TabTitle>,
        children: (
          <TicketEmbeddedContainer
            staticFilters={{ deviceId: [deviceData.device.id] }}
          >
            <TicketList />
          </TicketEmbeddedContainer>
        ),
      });
    }

    if (hasVariables(deviceData)) {
      tabItems.push({
        key: 'variables',
        label: <TabTitle>Variables</TabTitle>,
        children: <DeviceVariablesTable />,
      });
    }

    if (canSeeKeys) {
      tabItems.push({
        key: 'keys',
        label: <TabTitle>Keys</TabTitle>,
        children: <DeviceKeysTable />,
      });
    }

    return tabItems;
  };

  return (
    <>
      {typeof deviceData !== 'undefined' && deviceData !== null && (
        <Tabs defaultActiveKey="attributes" items={getTabItems()} />
      )}
    </>
  );
};

export default DeviceTabs;
