import React from 'react';
import { Button, Modal } from 'antd';
import { ApexOptions } from 'apexcharts';

import ChartLarge from '@totem/components/assetInventory/ChartLarge';
import { graphPoint } from '@totem/components/assetInventory/types';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';

type Props = {
  field: string;
  chartOptions?: ApexOptions;
  centerLabel?: string;
  onItemClicked?: (point: graphPoint) => void;
  open: boolean;
  onClose: () => void;
};

const ChartModal = (props: Props) => {
  return (
    <Modal
      open={props.open}
      onCancel={props.onClose}
      title={
        <ModalTitle>
          {' '}
          <br />{' '}
        </ModalTitle>
      }
      confirmLoading={false}
      width={'80%'}
      footer={
        <ModalFooter>
          <Button onClick={props.onClose}>Close</Button>
        </ModalFooter>
      }
    >
      <ChartLarge
        field={props.field}
        chartOptions={props.chartOptions}
        centerLabel={props.centerLabel}
        onItemClicked={props.onItemClicked}
      />
    </Modal>
  );
};

export default ChartModal;
