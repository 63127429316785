import React, { ReactNode, useEffect, useState } from 'react';

import SquareFootageContext from '@totem/components/buildings/squareFootage/SquareFootageContext';
import { Items } from '@totem/components/buildings/squareFootage/types';
import {
  BuildingSummaryFilters,
  BuildingSummaryInput,
} from '@totem/components/buildingSummary/types';
import {
  addReportFilters,
  buildReportFilters,
} from '@totem/components/buildingSummary/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { BUILDINGS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  staticFilters?: BuildingSummaryFilters;
  input: BuildingSummaryInput;
  children?: ReactNode;
};

const SquareFootageContainer = ({ children, input, staticFilters }: Props) => {
  const [data, setData] = useState<Items>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildReportFilters(input),
    };

    params.filters = addReportFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    setIsLoading(true);

    const params: Params = buildParameters();

    fetch(`${BUILDINGS_ENDPOINT}/squareFootage`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((result: Items) => {
        setData(result);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [
    input.regionId,
    input.buildingId,
    input.name,
    input.searchName,
    input.type,
  ]);

  return (
    <SquareFootageContext.Provider
      value={{
        data,
        loading: isLoading,
      }}
    >
      {children}
    </SquareFootageContext.Provider>
  );
};

export default SquareFootageContainer;
